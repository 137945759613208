import React from "react"
import { Row, Col, Container, Form } from "react-bootstrap"
import smallBlueCovid from "../assets/images/small-blue-covid.png"
import aiCovid from "../assets/images/ai-covid.svg"
import analyticsCovid from "../assets/images/analytics-covid.svg"
import databaseCovid from "../assets/images/database-covid.svg"
import calendarCovid from "../assets/images/calendar-covid.svg"
import networkCovid from "../assets/images/network-covid.svg"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar/navBar"
import axios from "axios"
import { toast } from "react-toastify"
import Cookies from "universal-cookie";
import { API_ROOT, PANEL_API_ROOT } from "gatsby-env-variables"

class covid19 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      covidHeader: {},
      covidFeatures: [],
      name: "",
      position: "",
      company: "",
      email: "",
      telephone: "",
      newsletter: false,
      errors: {
        name: '',
        position: '',
        company: '',
        email: '',
        telephone: ''
      },
      pageTitle: 'covid19',
      metaArr: []

    }
  }
  componentDidMount(){
    if(window.$crisp && window.$crisp !== undefined){
      window.$crisp.push(['do', 'chat:hide']);
    }

    this.getSetting();
    this.setState(
      {winHeight: window.innerHeight}
    );
    window.addEventListener("resize", () => {
      this.setState({
        winHeight: window.innerHeight
      })
    });
    this.getSeoData();
  }
  getSeoData() {
    axios.get(API_ROOT + `/api/v1/seo/covid19`)
      .then((response) => {

        var data = response?.data?.data || [];
        var metaArr = [];
        data.map((e) => {
          if (e.type === 'title') {
            this.setState({
              pageTitle: e.value
            })
          } else {
            metaArr.push({
              name: e.type,
              content: e.value
            })
          }
        })

        this.setState({
          metaArr: metaArr
        })

      })
  }


    getSetting(){
    axios.get(API_ROOT+`/api/v1/settings`)
      .then( (response) => {
        if(response && response.data && response.data.data){
          this.setState({
            covidHeader: response.data.data.covid_header,
            covidFeatures: response.data.data.covid_features,
          })
        }
      })
  }
  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    let value = null;
    if(name === 'newsletter'){
      value = target.checked;
    } else {
      value = target.value;
    }
    this.setState({
      [name]: value
    })
  }
  validateForm = () => {
    let validate = false;
    let errors = this.state.errors;
    if(!this.state.name){
      errors.name = 'Required Filed';
      validate = false;
    } else {
      errors.name = '';
      validate = true;
    }
    if(!this.state.company){
      errors.company = 'Required Filed';
      validate = false;
    } else {
      errors.company = '';
      validate = true;
    }
    if(!this.state.email){
      errors.email = 'Required Filed';
      validate = false;
    } else {
      errors.email = '';
      validate = true;
    }
    this.setState({errors : errors})
    return validate
  }
  validateHaveValue = () => {
    var obj = {
      name: this.state.name,
      company: this.state.company,
      email: this.state.email
    }
    if(this.state.position){
      obj.position = this.state.position;
    }
    if(this.state.telephone){
      obj.telephone = this.state.telephone;
    }
    for(var key in obj){
      if (obj[key] === ''){
        return false
      }
    }
    return true
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm() && this.validateHaveValue()){
      var requestObj = this.state;
      if(requestObj.newsletter === true){
        requestObj.newsletter = 1;
      } else {
        requestObj.newsletter = 0;
      }
      delete requestObj.errors
      delete requestObj.covidHeader
      delete requestObj.covidFeatures
      delete requestObj.winHeight
      axios.post(API_ROOT+`/api/v1/requests`,
        requestObj
      )
        .then((response) => {
          toast.success('Thank you for your request. We will get back to you as soon as possible.')
        })
        .catch(function (error) {
          if(error.response && error.response.status && error.response.status === 422){
            if(error.response.data && error.response.data.errors){
              for(var key in error.response.data.errors){
                var errorMsg  = error.response.data.errors[key];
                toast.error(errorMsg[0])
              }
            }
          } else{
            toast.error('an error occured.')
          }
        });
    }
  }
  render() {
    return (
      <Layout pageInfo={{ pageName: "index" }}>
        <SEO title={this.state.pageTitle}  meta = {this.state.metaArr} />
        <Container fluid className={'covid19 white-bg'}>
          {/*first section*/}
          <Row>
            <Col className='fullHeight' style={{height: this.state.winHeight}}>
              <Navbar pageInfo={{ pageName: "covid19" }} />
              <Row className={'fullheight intro-section'}>
                <Col xl={4} md={6} sm={6} className={'align-middle d-flex align-items-center justify-content-center'}>
                  <Col className={'first-section-card'} md={{span:10, offset:1}}>
                    <p>
                      {this.state.covidHeader && this.state.covidHeader.title}
                    </p>
                    <p>
                      {this.state.covidHeader && this.state.covidHeader.description}
                    </p>
                    <AnchorLink offset='0' href='#register'><button className='btn more-btn'>Free Sign Up</button></AnchorLink>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>

          {/*team section*/}
          <Row id={'team'}>
            <Col className='fullHeight'>
              <Row className='pt100 pb100 mt100 parent-covid'>
                <Col md={{span:10, offset:1}} className={'align-middle d-flex align-items-center justify-content-center'}>
                  <Col md={12} lg={12} xl={12}>
                    <Row className={'text-center'}>
                      <Col className={'mb20'} lg={{span: 4, offset:0}}  md={{span: 4, offset:0}}  sm={{span: 6, offset:3}}>
                        <Col className={'covid-feature'} md={{span: 12}}>
                          <div className={'covid1'}></div>
                          <p className={'title'}>
                            {this.state.covidFeatures && this.state.covidFeatures[0] && this.state.covidFeatures[0].title}
                          </p>
                          <ul className={'description'}>
                            {this.state.covidFeatures && this.state.covidFeatures[0] && this.state.covidFeatures[0].description && this.state.covidFeatures[0].description.map((description) => (
                              <li>{description}</li>
                            ))}
                          </ul>
                        </Col>
                      </Col>
                      <Col className={'mb20'} lg={{span: 4, offset:0}}  md={{span: 4, offset:0}}  sm={{span: 6, offset:3}}>
                        <Col className={'covid-feature'} md={{span: 12}}>
                          <div className={'covid2'}></div>
                          <p className={'title'}>
                            {this.state.covidFeatures && this.state.covidFeatures[1] && this.state.covidFeatures[1].title}
                          </p>
                          <ul className={'description'}>
                            {this.state.covidFeatures && this.state.covidFeatures[1] && this.state.covidFeatures[1].description && this.state.covidFeatures[1].description.map((description) => (
                              <li>{description}</li>
                            ))}
                          </ul>
                        </Col>
                      </Col>
                      <Col className={'mb20'} lg={{span: 4, offset:0}}  md={{span: 4, offset:0}}  sm={{span: 6, offset:3}}>
                        <Col className={'covid-feature'} md={{span: 12}}>
                          <div className={'covid3'}></div>
                          <p className={'title'}>
                            {this.state.covidFeatures && this.state.covidFeatures[2] && this.state.covidFeatures[2].title}
                          </p>
                          <ul className={'description'}>
                            {this.state.covidFeatures && this.state.covidFeatures[2] && this.state.covidFeatures[2].description && this.state.covidFeatures[2].description.map((description) => (
                              <li>{description}</li>
                            ))}
                          </ul>
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>

          {/*Why Riskilick section*/}
          <Row>
            <Col className='fullHeight'>
              <Row className={'invest-section'}>
                <Col md={4} className={'align-middle d-flex align-items-center justify-content-center'}>
                  <Col className={'text-left invest'} md={{span:10, offset:1}}>
                    <p className={'sections-title-left sections-title-style'}>Why Riskilick COVID-19 AI</p>
                  </Col>
                </Col>
                <Col md={8} className={'align-middle d-flex align-items-center justify-content-center '}>
                  <Col className={'content-box right-invest-section'} md={{span:10, offset:1}}>
                    <Row className={'rtl'}>
                      <div className="col-lg-4 col-md-6 col-sm-6 p-0">
                        <Col className={'invest-card'} md={{span:10, offset:1}} sm={{span:10, offset:1}}>
                          <div className='img-container ai-covid'>
                            <img src={aiCovid} alt="Artificial intelligence technology"/>
                          </div>
                          <p>Artificial intelligence technology</p>
                        </Col>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 p-0">
                        <Col className={'invest-card'} md={{span:10, offset:1}} sm={{span:10, offset:1}}>
                          <div className='img-container analytics-covid'>
                            <img src={analyticsCovid} alt="Finding relevant information in short time"/>
                          </div>
                          <p>Finding most relevant information in short time</p>
                        </Col>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 p-0">
                        <Col className={'invest-card vertical-center-card'} md={{span:10, offset:1}} sm={{span:10, offset:1}}>
                          <div className='img-container database-covid'>
                            <img src={databaseCovid} alt="comprehensive COVID-19 database"/>
                          </div>
                          <p>Most comprehensive COVID-19 database</p>
                        </Col>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 p-0">
                        <Col className={'invest-card'} md={{span:10, offset:1}} sm={{span:10, offset:1}}>
                          <div className='img-container network-covid'>
                            <img src={networkCovid} alt="Finding right collaborator"/>
                          </div>
                          <p>Finding right collaborator</p>
                        </Col>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 p-0">
                        <Col className={'invest-card'} md={{span:10, offset:1}} sm={{span:10, offset:1}}>

                          <div className='img-container calendar-covid'>
                            <img src={calendarCovid} alt="Daily update"/>
                          </div>
                          <p>Daily update</p>
                        </Col>
                      </div>
                    </Row>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>

          {/*register section*/}
          <Row id={'register'}>
            <Col className='fullHeight'>
              <Row>
                <Col md={4} className={'align-middle d-flex align-items-center justify-content-center'}>
                  <Col className={'request-covid'} md={{span:11, offset:1}}>
                    <img src={smallBlueCovid} alt="covid19" width={'42'}/>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col md={12} className={'align-middle d-flex align-items-center justify-content-center'}>
                  <Col className={'text-left invest pl-0'} md={{span:11}}>
                    <p className={'sections-title-left sections-title-style'}>
                      Beta version free access
                    </p>
                  </Col>
                </Col>
              </Row>
              <Row className={'align-middle d-flex align-items-center justify-content-center'}>
                <Col md={{span: 11}}>
                  <form onSubmit={this.handleSubmit}>
                    <Row className={'covid-register'}>
                      <Col className={'mb20'} md={{span:5, offset:1}}>
                        <Form.Group>
                          <Form.Label>Name*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name={"name"}
                            value={this.state.name}
                            onChange={this.handleInputChange}
                            className={this.state.errors.name !== '' ? "has-error" : ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb20'} md={{span:5}}>
                        <Form.Group>
                          <Form.Label>Position</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name={"position"}
                            value={this.state.position}
                            onChange={this.handleInputChange}
                            className={this.state.errors.position !== '' ? "has-error" : ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb20'} md={{span:5, offset:1}}>
                        <Form.Group>
                          <Form.Label>Company*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name={"company"}
                            value={this.state.company}
                            onChange={this.handleInputChange}
                            className={this.state.errors.company !== '' ? "has-error" : ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb20'} md={{span:5}}>
                        <Form.Group>
                          <Form.Label>Email*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name={"email"}
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            className={this.state.errors.email !== '' ? "has-error" : ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb20'} md={{span:5, offset:1}}>
                        <Form.Group>
                          <Form.Label>Telephone</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name={"telephone"}
                            value={this.state.telephone}
                            onChange={this.handleInputChange}
                            className={this.state.errors.telephone !== '' ? "has-error" : ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb20 mt20'} md={{span:10, offset:1}}>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            label="Yes, I’m interested in receiving newsletter"
                            name="newsletter"
                            value={this.state.newsletter}
                            onChange={this.handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb100 text-center'} md={{span:12}}>
                        <button type='submit' className={'btn submit-btn'} >
                          Submit request
                        </button>
                      </Col>
                      <Col md={12} className="footer">
                        <p>Copyright 2020 Risklick.ch</p>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default covid19
