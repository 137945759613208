import React, { useState, useEffect } from "react";
import logo from "../../assets/images/risklick-logo.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { API_ROOT, PANEL_API_ROOT } from "gatsby-env-variables";
import axios from "axios";
import { Link } from "gatsby";

import styles from "./navbar.module.scss";

const CustomNavbar = ({ pageInfo }) => {
  const [getData, setCount] = useState(false);
  const [blogCategory, setBlogCategory] = useState("");

  useEffect(() => {
    if (getData === false) {
      setCount(true);
      axios
        .get(API_ROOT + `/api/v1/blog-categories?show_in_menu=1`)
        .then((response) => {
          let blogCategory = response?.data?.data || [];
          setBlogCategory(blogCategory);
        });
    }
  });

  return (
    <div className={`d-flex justify-content-center row-fluid ${styles.navbar}`}>
      <Navbar className="p-0" variant="light" expand="lg" id="site-navbar">
        <Link to="https://www.risklick.ch" className="link-no-style">
          <Nav.Link as="span">
            <img src={logo} alt="Logo" className={"navbar-logo"} />
          </Nav.Link>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`ml-auto ${styles.navbar__container}`}>
            {pageInfo && pageInfo.pageName && pageInfo.pageName !== "index" ? (
              <Nav.Link className={styles.navbar__item_link} href="/">
                Home
              </Nav.Link>
            ) : (
              <Nav.Link className={styles.navbar__item} active>
                <AnchorLink className={styles.navbar__item_link}>
                  Home
                </AnchorLink>
              </Nav.Link>
            )}

            <NavDropdown
              className="navbar__item_dropdown"
              title="Product &amp; Services"
            >
              <NavDropdown.Item className="navbar__item_dropdown_section_title">
                Products
              </NavDropdown.Item>
              <NavDropdown.Item
                className="navbar__item_dropdown_section_item"
                href="/deduklick"
              >
                Deduklick
              </NavDropdown.Item>
              <NavDropdown.Item className="navbar__item_dropdown_section_title">
                Services
              </NavDropdown.Item>
              <NavDropdown.Item
                className="navbar__item_dropdown_section_item"
                href="/service/meddev"
              >
                MedDev Intelligence
              </NavDropdown.Item>
              <NavDropdown.Item
                className="navbar__item_dropdown_section_item"
                href="/service/risk-analyzer"
              >
                Trial Risk Analyzer
              </NavDropdown.Item>
              <NavDropdown.Item
                className="navbar__item_dropdown_section_item"
                href="/service/design-studio"
              >
                Design Studio
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link className={styles.navbar__item_link} href="/academy">
              Academy
            </Nav.Link>

            {pageInfo && pageInfo.pageName && pageInfo.pageName !== "index" ? (
              <Nav.Link className={styles.navbar__item_link} href="/team">
                Team
              </Nav.Link>
            ) : (
              <Nav.Link href="/team">
                <AnchorLink className={styles.navbar__item_link} href="/team">
                  Team
                </AnchorLink>
              </Nav.Link>
            )}

            {pageInfo && pageInfo.pageName && pageInfo.pageName !== "index" ? (
              <Nav.Link className={styles.navbar__item_link} href="/">
                News
              </Nav.Link>
            ) : (
              <Nav.Link href="#news">
                <AnchorLink className={styles.navbar__item_link} href="#news">
                  News
                </AnchorLink>
              </Nav.Link>
            )}

            <Link
              className={`${styles.navbar__item_link} link-no-style`}
              to="/jobs"
            ></Link>
            <Link
              to={PANEL_API_ROOT }
              className={"login-btn"}
            >
              Login
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
